import { MultiCloudVisibleNodesStrategy } from "./MultiCloudVisibleNodesStrategy";

/** A tuple for weight and count of a node. Used over an object to speed up sorting */
export type WeightCountTuple = [weight: number, count: number];

/**
 * Shared instance between multiple MultiCloudVisibleNodesStrategies to manage the allocation of point resources.
 *
 * The point allocation is done, by calculating a minimum weight for nodes to be visible. When a strategy is wrapped in a
 * MultiCloudVisibleNodesStrategy, this minimum weight will be used to limit the nodes loaded.
 */
export class MultiCloudPointBudgetManager {
	/**
	 * Creates a new MultiCloudVisibleNodesStrategy
	 *
	 * @param maxPointsInGpu the number of points shared between all clouds
	 */
	constructor(maxPointsInGpu: number) {
		this.#maxPointsInGpu = maxPointsInGpu;
	}

	/** The strategies this instance manages the point budget for */
	private individualStrategies = new Set<MultiCloudVisibleNodesStrategy>();

	#minWeight = 0;
	/** @returns the minimum weight required for a node to be visible. Updated using the update() call. */
	get minWeight(): number {
		return this.#minWeight;
	}

	/**
	 * Adds a visibility strategy to the shared pool of points.
	 *
	 * @param strategy the visibility strategy for the point cloud to manage
	 */
	public addStrategy(strategy: MultiCloudVisibleNodesStrategy): void {
		this.individualStrategies.add(strategy);

		strategy.maxPointsInGpu = this.#maxPointsInGpu;
	}

	/**
	 * Removes a visibility strategy from the shared pool of points.
	 *
	 * @param strategy the visibility strategy for the point cloud to manage
	 */
	public removeStrategy(strategy: MultiCloudVisibleNodesStrategy): void {
		this.individualStrategies.delete(strategy);
	}

	/**
	 * Updates the shared resource allocation for all point clouds. Should be called, after the node weights have been updated
	 */
	update(): void {
		const allWeights: WeightCountTuple[] = Array.from(this.individualStrategies).flatMap(
			(strategy) => strategy.lastVisibleNodes,
		);

		allWeights.sort((a, b) => b[0] - a[0]);

		let cutoffMinWeight = 0;
		let numPoints = 0;
		for (const [weight, count] of allWeights) {
			numPoints += count;

			if (numPoints >= this.maxPointsInGpu) {
				cutoffMinWeight = weight;
				break;
			}
		}

		this.#minWeight = cutoffMinWeight;
	}

	#maxPointsInGpu: number;
	/** @returns the max number of points shared across all point clouds */
	get maxPointsInGpu(): number {
		return this.#maxPointsInGpu;
	}
	/** sets the max number of points shared across all point clouds  */
	set maxPointsInGpu(n: number) {
		this.#maxPointsInGpu = n;

		for (const strategy of this.individualStrategies) {
			strategy.maxPointsInGpu = n;
		}
	}
}
