import { Camera, Matrix4, Vector2 } from "three";
import { LodTree } from "./LodTree";
import { MultiCloudPointBudgetManager, WeightCountTuple } from "./MultiCloudPointBudgetManager";
import { OptimizationHint, VisibleNodesStrategy, WeightedNode } from "./VisibleNodeStrategy";

/**
 * Strategy to implement a shared multi-cloud point limit on top of another VisibleNodesStrategy.
 * Communicates with a MultiCloudPointBudgetManager instance, which handles the resource allocation.
 */
export class MultiCloudVisibleNodesStrategy implements VisibleNodesStrategy {
	/**
	 * Creates a new MultiCloudVisibleNodesStrategy for an exisiting strategy
	 *
	 * @param pointBudgetManager the point budget manager
	 * @param originalStrategy the existing strategy to manage
	 */
	constructor(
		private pointBudgetManager: MultiCloudPointBudgetManager,
		public readonly originalStrategy: VisibleNodesStrategy,
	) {}

	/** Cached visibility weights used for calculating the shared resource allocation */
	public lastVisibleNodes: WeightCountTuple[] = [];

	/** @inheritdoc */
	compute(tree: LodTree, cloudWorldMatrix: Matrix4, camera: Camera, screenSize: Vector2): WeightedNode[] {
		const nodes = this.originalStrategy.compute(tree, cloudWorldMatrix, camera, screenSize);

		this.lastVisibleNodes = nodes.map((n) => [n.weight, tree.getNode(n.id).numPoints]);

		return nodes.filter((n) => n.weight >= this.pointBudgetManager.minWeight);
	}

	/** @inheritdoc */
	get maxPointsInGpu(): number {
		return this.originalStrategy.maxPointsInGpu;
	}
	/** @inheritdoc */
	public set maxPointsInGpu(n: number) {
		this.originalStrategy.maxPointsInGpu = n;
	}

	/** @returns the target point density on screen, used to determine how many nodes to render from the LOD tree. */
	get targetPixelsPerPoint(): number {
		return this.originalStrategy.targetPixelsPerPoint;
	}
	/** sets the targetPixelsPerPoint for all individual strategies */
	set targetPixelsPerPoint(n: number) {
		this.originalStrategy.targetPixelsPerPoint = n;
	}

	/** @inheritdoc */
	get optimizationHint(): OptimizationHint {
		return this.originalStrategy.optimizationHint;
	}
	/** @inheritdoc */
	set optimizationHint(hint: OptimizationHint) {
		this.originalStrategy.optimizationHint = hint;
	}

	/** @inheritdoc */
	clone(): VisibleNodesStrategy {
		const clone = new MultiCloudVisibleNodesStrategy(this.pointBudgetManager, this.originalStrategy.clone());
		return clone;
	}
}
